.custom-table {
  font-size: 16px;
}

.custom-table td {
  padding: 8px;
  border: 0dvi solid #e8e8e8;
}

.custom-table .ant-table-tbody>tr:hover>td {
  background-color: #f5f5f5;
}

.custom-table .ant-table-tbody>tr>td:first-child {
  font-weight: bold;
}

.custom-table .ant-table-tbody>tr>td:last-child {
  color: #000000;
}

.centerContent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.alignRight {
  display: flex;
  justify-content: flex-end;
}

.hidden-input {
  display: none;
}

.preview-container {
  width: 100px;
  /* Adjust as needed */
  height: 100px;
  /* Adjust as needed */
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #ddd;
  /* Border color */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: 4%;
}

.preview-image, .preview-video {
  max-width: 100%;
  max-height: 90%;
  display: block;
  object-fit: cover;
}

.delete-icon {
  position: absolute;
  top: -6px;
  right: -7px;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.uploaded-files {
  display: flex;
  position: relative;
}

.my-masonry-grid_column {
  width: 180px !important;
}

.media-item {
  width: 180px;
  height: 150px;
  object-fit: cover;
}

.img-shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.img-shadow:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.comment-bottom-border {
  border-bottom: 1px solid #e2e8f0;
}